/*
This file is autogenerated. Don't change it directly.
Instead, change the "addons" setting in your package.json file.
*/

const projectConfigLoader = require('@package/config');
import kitconceptvoltoBlocksGrid from '@kitconcept/volto-blocks-grid';
import voltoAuthomatic from 'volto-authomatic';
import eeacmsvoltoObjectWidget from '@eeacms/volto-object-widget';
import voltoSlate, { asDefault as asDefault0 } from 'volto-slate';
import voltoSubblocks from 'volto-subblocks';
import voltoFormBlock from 'volto-form-block';

const safeWrapper = (func) => (config) => {
  const res = func(config);
  if (typeof res === 'undefined') {
    throw new Error("Configuration function doesn't return config");
  }
  return res;
}

const projectConfig = (config) => {
  return typeof projectConfigLoader.default === "function" ? projectConfigLoader.default(config) : config;
}

const load = (config) => {
  const addonLoaders = [kitconceptvoltoBlocksGrid, voltoAuthomatic, eeacmsvoltoObjectWidget, voltoSlate, asDefault0, voltoSubblocks, voltoFormBlock];
  if(!addonLoaders.every((el) => typeof el === "function")) {
    throw new TypeError(
      'Each addon has to provide a function applying its configuration to the projects configuration.',
    );
  }
  return projectConfig(addonLoaders.reduce((acc, apply) => safeWrapper(apply)(acc), config));
};
export default load;
